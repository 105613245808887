import s from './AddElementToArray.module.scss'
import React from "react";
import {Back} from "components/Back/Back";
import {useTranslation} from "react-i18next";
import {Form, Formik, FormikErrors, FormikHelpers, FormikTouched, FormikValues} from "formik";
import cn from "classnames";
import {DropDown} from "components/DropDown/DropDown";
import {useSelector} from "react-redux";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useStatusContext} from "components/StatusProvider";
import {useAddToArrayModuleMutation} from "../../../../../../redux/apis/modulesApi";

type AddElementToArrayProps = {
    setElementOfArray:  React.Dispatch<any>;
    elementOfArray: any;
    moduleId: number | undefined;
    refetchGetSection: any;
}

type DropdownProps = {
    value: any;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldValue: any;
    values:  FormikValues;
}

export const AddElementToArray = ({ setElementOfArray, elementOfArray, moduleId, refetchGetSection }: AddElementToArrayProps) => {
    const { t } = useTranslation();
    const langs = useSelector((store: any) => store.main.langs);
    const { openStatus } = useStatusContext();
    const [addToArray] = useAddToArrayModuleMutation();

    console.log(elementOfArray?.element);

    const classType = (type: string) => cn(s.main_info, {
        [s.type_style]: type === "text" || type === "image" || type === "video"||  type === "link",
        [s.type_array]: type === "array",
    });

    const onSubmit = async (values: any, { resetForm }: FormikHelpers<any>) => {
        let copyArray = elementOfArray?.element;

        let valuesLang: any = [];

        langs?.map((l: any) => {
            for (let val in values) {
                if (val.includes(l.key)) {
                    if (values?.type === "image" || values?.type === "video") {
                        valuesLang.push({
                            file: values[val],
                            lang_key: l.key
                        })
                    } else {
                        valuesLang.push({
                            value: values[val],
                            lang_key: l.key
                        })
                    }
                }
            }
        })

        const processValues = (values: any[]): any => {
            console.log(values);

            return values?.map((element: any) => {
                if (element?.type !== "object" && element?.type !== "array") {
                    console.log(element);
                    console.log(valuesLang);
                    return { ...element, values: valuesLang };
                } else {
                    if (Array.isArray(element.values)) {
                        return { ...element, values: processValues(element.values) };
                    }

                    return element
                }
            });
        };

        const updatedValues = processValues(copyArray?.values || []);
        copyArray = { ...copyArray, values: (copyArray?.type === "array" || copyArray?.type === "object") ? updatedValues: valuesLang };

        const result: any = {
            module_id: moduleId,
            module: copyArray
        }

        console.log(copyArray);

        const response: any = await addToArray(result).unwrap();

        if (response.message === "ok") {
            openStatus('success', "status.operationSuccessful");
            setElementOfArray({show: false});
            refetchGetSection();
            resetForm();
        } else {
            openStatus('error', "status.error");
        }
    }

    const renderMainInfo = ({value, errors, touched, setFieldValue, values}: DropdownProps) => {
        return (
            <div className={classType(value?.type)}>
                {(value?.type === "object" || value?.type === "array") && <p className={s.type}>{value?.type}</p>}
                {(value?.type === "object" || value?.type === "array") &&
                    value?.values?.map((val: any) => {
                        if (val?.values) {
                            return renderMainInfo({
                                value: val,
                                errors,
                                touched,
                                setFieldValue,
                                values
                            });
                        }
                    })
                }
                {(value?.type !== "array" && value?.type !== "object") && (
                    <DropDown
                        langs={langs}
                        errors={errors}
                        touched={touched}
                        kind={value?.type + value.id}
                        label={value?.type}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                )}
            </div>
        )
    }

    return (
        <div className={s.container}>
            <Back close={setElementOfArray} text={t("blocksModal.backToBlocks")} />
            <div className={s.form}>
                <h2>{t("general.addANewItem")}</h2>

                <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => {
                            return (
                                <Form className={s.container_elements}>
                                    {elementOfArray?.element?.type !== "array" && elementOfArray?.element?.type !== "object" ?
                                      renderMainInfo({
                                            value: elementOfArray?.element,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            values
                                        })
                                    : Array.isArray(elementOfArray?.element?.values) &&
                                        elementOfArray?.element?.values?.map((val: any, index: number) => {
                                            if (val?.values) {
                                                return renderMainInfo({
                                                    value: val,
                                                    errors,
                                                    touched,
                                                    setFieldValue,
                                                    values
                                                });
                                            }
                                        })}
                                    <div className={s.wrap_buttons}>
                                        <PrimaryButton
                                            text={t("general.confirm")}
                                            type={"submit"}
                                            icon={<Check/>}
                                        />
                                        <SecondaryButton
                                            text={t("general.cancel")}
                                            type={"button"}
                                            white
                                            onClick={() => setElementOfArray(false)}
                                        />
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}